@media(min-width: 768px){
    .title-in-navbar{
        font-size: 1.5rem;
    }
}

/* .tolm-blue-button{
    background-color: "#002060";
    border-color: "#002060";
} */

.img {
    float: center;
    width:  125px;
    height: 125px;
    background-size: cover;
}

/* .container{
    margin-bottom: 100px;
} */


.logo{
    max-width: 125px;
}

.header-margin{
    margin-top: 50px;
}

@media screen and (max-width: 767px) {
    .mobile-top-margin {
        margin-top: 25px;
    }
    
    .header-margin{
        margin-top: 20px;
    }

    .logo{
        max-width: 50px;
    }
}

.navy-button{
    width: 100%;
    background-color: #00274c;
    border-color: #00274c;
    color: white;
    border-radius: .3rem;
    font-weight: 400;
    white-space: nowrap;
    display: inline-block;
    font-size: 1.25rem;
    padding: .2rem 1rem;
}

h1, h2, h3, h4, h5{
    font-weight: 350;
}

p{
    font-weight: 400;
}