.bread-crumbs{
    /* background-color: #17a2b8; */
    /* color: black; */
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.link {
    color:white;
    -o-transition:color .3s ease-out;
    -ms-transition:color .3s ease-out;
    -moz-transition:color .3s ease-out;
    -webkit-transition:color .3s ease-out;
    transition:color .3s ease-out;
    text-decoration: none;
}
.link:hover { 
    color: #17a2b8;
    text-decoration: none;
}