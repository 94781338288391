/*Social media icons in footer*/
.social a{
    font-size: 3em;
    padding: 1.5rem;
}
.fa-facebook{
    color: #3b5998;
}
.fa-instagram{
    color: #517FA8
}

.fa-facebook:hover, 
.fa-instagram:hover{
    color: #d5d5d5;
}

.footer{
    background-color: #00274c;
    color: #d5d5d5;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
hr.light{
    border-top: 1px solid #d5d5d5;
    width: 75%;
    margin-top: .8rem;
    margin-bottom: 1rem;
}
.footer a{
    color: #d5d5d5
}
hr.light-100{
    border-top: 1px solid #d5d5d5;
    width: 1%;
    margin-top: .8rem;
    margin-bottom: 1rem;
}

/*Media Queries*/
@media(max-width: 992px){
    .social a{
        font-size: 2.5em;
        padding: 1rem;
    }
}

@media(max-width: 768px){
    .social a{
        font-size: 2em;
        padding: .7em;
    }
}

@media(max-width: 576px){
    .social a{
        font-size: 1.5em;
        padding: .5rem;
    }
}